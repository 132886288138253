.Section-module_first__1vLBH .MediaPlayer-module_wrapper__1cSGR img,
.Section-module_first__1vLBH .video-js {
  mask-image: url("../../website/images/devicesMask.png");
  mask-size: contain;
}

.Section-module_first__1vLBH .MediaPlayer-module_wrapper__1cSGR::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../website/images/devices.png");
  background-size: contain;
  z-index: 1;
  pointer-events: none;
}

.Section-module_first__1vLBH .FitViewport-module_inner__3psd1 {
  background-color: transparent;
}
